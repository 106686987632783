<template>
  <div>
    <title-bar title="Galerie anlegen">
      <router-link slot="right" to="/galleries" class="button is-primary">
        <b-icon
          :icon="$func.getIcon('back').icon"
          :pack="$func.getIcon('back').pack"
          class="mr-1"
        />
        Galerie Übersicht
      </router-link>
    </title-bar>
    <section class="section is-main-section">
      <tiles>
        <card-component
          title="Details zur Veranstaltung"
          :icon="$func.getIcon('gallery').icon"
          :icon-pack="$func.getIcon('gallery').pack"
          class="tile is-child"
        >
          <form @submit.prevent="submitGallery">
            <b-field label="Name" horizontal>
              <b-input
                v-model="gallery.name"
                placeholder="e.g. Party XYZ"
                minlength="2"
                required
              />
            </b-field>

            <b-field label="Bilder" horizontal>
              <b-upload
                v-model="dropFiles"
                multiple
                drag-drop
                @input="validateUpload"
              >
                <section class="section">
                  <div class="content has-text-centered">
                    <p>
                      <b-icon
                        :icon="$func.getIcon('upload').icon"
                        :pack="$func.getIcon('upload').pack"
                        size="is-large"
                      >
                      </b-icon>
                    </p>
                    <p>
                      Dateien hier per Drag &amp; Drop ablegen um Upload zu
                      starten
                    </p>
                  </div>
                </section>
              </b-upload>
            </b-field>

            <b-field label="" horizontal>
              <div class="tags">
                <span
                  v-for="(file, index) in dropFiles"
                  :key="index"
                  class="tag is-primary"
                >
                  {{ file.name }}
                  <button
                    class="delete is-small"
                    type="button"
                    @click="deleteDropFile(index)"
                  ></button>
                </span>
              </div>
            </b-field>

            <b-field label="Deaktiviert" horizontal>
              <b-switch v-model="gallery.disable">
                {{ gallery.disable ? "Ja" : "Nein" }}
              </b-switch>
            </b-field>

            <hr />

            <b-field horizontal>
              <b-button
                type="is-primary"
                native-type="submit"
                :icon-left="$func.getIcon('save').icon"
                :icon-pack="$func.getIcon('save').pack"
              >
                Galerie anlegen
              </b-button>
            </b-field>
          </form>
        </card-component>
      </tiles>
    </section>

    <b-loading :is-full-page="true" :active="isLoading" />
  </div>
</template>

<script>
import api from "@/utils/api.js";
import TitleBar from "@/components/TitleBar";
import Tiles from "@/components/Tiles";
import CardComponent from "@/components/CardComponent";

export default {
  name: "GalleryNew",
  components: {
    CardComponent,
    Tiles,
    TitleBar
  },
  data() {
    return {
      isLoading: false,
      dropFiles: [],
      gallery: {
        name: null
      }
    };
  },
  methods: {
    validateUpload(value) {
      value.forEach(f => {
        if (f.type !== "image/png" && f.type !== "image/jpeg") {
          this.$buefy.toast.open({
            message: `Dateityp von ${f.name} nicht erlaubt!`,
            type: "is-danger",
            duration: 5000,
            queue: false
          });
        }
      });
      this.dropFiles = this.dropFiles.filter(f => {
        return f.type === "image/png" || f.type === "image/jpeg";
      });
    },
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
    submitGallery() {
      this.isLoading = true;
      api
        .post(`/galleries`, this.gallery)
        .then(r => {
          if (this.dropFiles.length > 0) {
            var promises = [];
            for (const i in this.dropFiles) {
              var reader = new FileReader();
              var that = this;
              reader.addEventListener(
                "load",
                function() {
                  promises.push(
                    api.post(`/galleries/${r.data.id}/photos`, {
                      img_base64: this.result
                    })
                  );

                  if (promises.length === that.dropFiles.length) {
                    Promise.all(promises)
                      .then(() => {
                        that.createdAndNavBack();
                      })
                      .catch(() => (this.isLoading = false));
                  }
                },
                false
              );
              reader.readAsDataURL(this.dropFiles[i]);
            }
          } else {
            this.createdAndNavBack();
          }
        })
        .catch(() => (this.isLoading = false));
    },
    createdAndNavBack() {
      this.isLoading = false;
      this.$buefy.snackbar.open({
        message: "Galerie angelegt",
        queue: false
      });
      this.$router.push({ name: "galleries" });
    }
  }
};
</script>
