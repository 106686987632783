var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "title-bar",
        { attrs: { title: "Galerie anlegen" } },
        [
          _c(
            "router-link",
            {
              staticClass: "button is-primary",
              attrs: { slot: "right", to: "/galleries" },
              slot: "right"
            },
            [
              _c("b-icon", {
                staticClass: "mr-1",
                attrs: {
                  icon: _vm.$func.getIcon("back").icon,
                  pack: _vm.$func.getIcon("back").pack
                }
              }),
              _vm._v(" Galerie Übersicht ")
            ],
            1
          )
        ],
        1
      ),
      _c(
        "section",
        { staticClass: "section is-main-section" },
        [
          _c(
            "tiles",
            [
              _c(
                "card-component",
                {
                  staticClass: "tile is-child",
                  attrs: {
                    title: "Details zur Veranstaltung",
                    icon: _vm.$func.getIcon("gallery").icon,
                    "icon-pack": _vm.$func.getIcon("gallery").pack
                  }
                },
                [
                  _c(
                    "form",
                    {
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.submitGallery.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _c(
                        "b-field",
                        { attrs: { label: "Name", horizontal: "" } },
                        [
                          _c("b-input", {
                            attrs: {
                              placeholder: "e.g. Party XYZ",
                              minlength: "2",
                              required: ""
                            },
                            model: {
                              value: _vm.gallery.name,
                              callback: function($$v) {
                                _vm.$set(_vm.gallery, "name", $$v)
                              },
                              expression: "gallery.name"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-field",
                        { attrs: { label: "Bilder", horizontal: "" } },
                        [
                          _c(
                            "b-upload",
                            {
                              attrs: { multiple: "", "drag-drop": "" },
                              on: { input: _vm.validateUpload },
                              model: {
                                value: _vm.dropFiles,
                                callback: function($$v) {
                                  _vm.dropFiles = $$v
                                },
                                expression: "dropFiles"
                              }
                            },
                            [
                              _c("section", { staticClass: "section" }, [
                                _c(
                                  "div",
                                  { staticClass: "content has-text-centered" },
                                  [
                                    _c(
                                      "p",
                                      [
                                        _c("b-icon", {
                                          attrs: {
                                            icon: _vm.$func.getIcon("upload")
                                              .icon,
                                            pack: _vm.$func.getIcon("upload")
                                              .pack,
                                            size: "is-large"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c("p", [
                                      _vm._v(
                                        " Dateien hier per Drag & Drop ablegen um Upload zu starten "
                                      )
                                    ])
                                  ]
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c("b-field", { attrs: { label: "", horizontal: "" } }, [
                        _c(
                          "div",
                          { staticClass: "tags" },
                          _vm._l(_vm.dropFiles, function(file, index) {
                            return _c(
                              "span",
                              { key: index, staticClass: "tag is-primary" },
                              [
                                _vm._v(" " + _vm._s(file.name) + " "),
                                _c("button", {
                                  staticClass: "delete is-small",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteDropFile(index)
                                    }
                                  }
                                })
                              ]
                            )
                          }),
                          0
                        )
                      ]),
                      _c(
                        "b-field",
                        { attrs: { label: "Deaktiviert", horizontal: "" } },
                        [
                          _c(
                            "b-switch",
                            {
                              model: {
                                value: _vm.gallery.disable,
                                callback: function($$v) {
                                  _vm.$set(_vm.gallery, "disable", $$v)
                                },
                                expression: "gallery.disable"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.gallery.disable ? "Ja" : "Nein") +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c("hr"),
                      _c(
                        "b-field",
                        { attrs: { horizontal: "" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                type: "is-primary",
                                "native-type": "submit",
                                "icon-left": _vm.$func.getIcon("save").icon,
                                "icon-pack": _vm.$func.getIcon("save").pack
                              }
                            },
                            [_vm._v(" Galerie anlegen ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("b-loading", {
        attrs: { "is-full-page": true, active: _vm.isLoading }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }